import './loader.component.scss';

export class LoaderComponent
{
    private static readonly elements = {
        pageLoader: '.page-loader-wrapper',
        asyncLoader: '.async-loader-wrapper',
    };

    public static showAsyncLoader(): void
    {
        $(this.elements.asyncLoader)
            .fadeIn('fast');
    }

    public static hideAsyncLoader(): void
    {
        $(this.elements.asyncLoader)
            .fadeOut('fast');
    }

    public static hidePageLoader(): void
    {
        $(this.elements.pageLoader)
            .delay(500)
            .fadeOut('slow');
    }
}