"use strict";

import {LoaderComponent} from "Components/global/loader.component";
import './base.controller.scss'
import cssVars from 'css-vars-ponyfill';

export class BaseController
{

    private readonly elements = {
        menuModal: '[data-modal="menu"]',
        navbarToggler: '.navbar-toggler[data-target="#navbarNav"]',
        menuButtonModal: '#navbarNav [data-toggle="modal"]',
        menuModalContainer: '.menu-modal-container',
        labelToAnimateContainer: '.animate-label',
    };

    constructor()
    {
        cssVars({});

        $(async () => {
            LoaderComponent.hidePageLoader();
            this.init();
        });
    }

    init(): void {}

    addBodyClassOnShowMenu(): void {
        let navbarToggler = this.elements.navbarToggler;
        $(this.elements.menuModal).on('show.bs.modal', () => {
            $('body').addClass('modal-on-content');
            if ($(navbarToggler).attr('aria-expanded') === 'true') {
                $(navbarToggler).trigger('click');
            }
        });
    }

    toggleItemClassOnShowMenu(): void {
        $(this.elements.menuButtonModal).on('click', (event) => {
            let elm = event.currentTarget;
            if ($(elm).parents('.nav-item').hasClass('modal-opened')) {
                $(elm).parents('.nav-item').removeClass('modal-opened');
            } else {
                $(elm).parents('.nav-item').addClass('modal-opened');
            }
            $(elm).parents('.nav-item').siblings('.nav-item').removeClass('modal-opened');
        });
    }

    collapseMenuModal(): void {
        let menuModalContainer = this.elements.menuModalContainer;
        $(this.elements.menuButtonModal).on('click', (event) => {
            let elm = event.currentTarget;
            let modalId = $(elm).attr('data-target');
            $(menuModalContainer).find('.menu-modal.show:not(' + modalId + ')').each((k, singleModal) => {
                // @ts-ignore
                $(singleModal).modal('hide');
            });
        });
    }

    getSource(parent: string): object
    {
        return $(parent).data('source');
    }

    toggleFormAnimation(): void {
        $(this.elements.labelToAnimateContainer).find('input').on('focus', (element) => {
            $(element.currentTarget).parents('.form-group').addClass('label-animation');
        });
        $(this.elements.labelToAnimateContainer).find('input').on('blur', (element) => {
            if ($(element.currentTarget).val() === '') {
                $(element.currentTarget).parents('.form-group').removeClass('label-animation');
            }
        });

    }
}
