import Swal, {SweetAlertIcon} from "sweetalert2";
import router from "Router";
import {AjaxRequest, AjaxRequestMethodType} from "UtilTypes/ajaxRequest";
import {AjaxResponse} from "UtilTypes/ajaxResponse";
import {LoaderComponent} from "Components/global/loader.component";

export class UtilServicesHelper {

    static readonly Routing = router ;

    static readonly type: {success: SweetAlertIcon, error: SweetAlertIcon, info: SweetAlertIcon, warning: SweetAlertIcon} = {
        success: 'success',
        error : 'error',
        info: 'info',
        warning: "warning"
    };

    static readonly ajaxMethodType: AjaxRequestMethodType = {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        DELETE: 'DELETE'
    };

    /**
     *
     * @param ajaxRequest
     */
    static deferredAjaxRequest<RES>(ajaxRequest : AjaxRequest): JQuery.Promise<AjaxResponse<RES>>
    {

        let deferred = $.Deferred();

        if(ajaxRequest.showAjaxLoader)
        {
            LoaderComponent.showAsyncLoader();
        }

        $.ajaxSetup({
            beforeSend: function (xhr,settings) {
                xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                xhr.setRequestHeader("Access-Control-Allow-Origin","*");
            }
        });

        $.ajax(ajaxRequest.requestParameters)
            .then((response)  => {

                LoaderComponent.hideAsyncLoader();

                let ajaxResponse:AjaxResponse<RES> = response;

                deferred.resolve(ajaxResponse);

            },(reject) => {

                LoaderComponent.hideAsyncLoader();

                deferred.reject(reject);
            });

        return deferred.promise();
    }

    static createFormDataFromHtmlElement(form: HTMLFormElement): FormData
    {
        return new FormData(form);
    }

    static goToPage(router:string): void
    {
        window.location.href = router;
    }

    static reloadPage()
    {
        window.location.reload();
    }

    /**
     * This function shows a swal info alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showInfoSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText:string = 'Sì',
        cancelButtonText:string = 'No',
        onConfirm: (...args: any[] ) => any = null,
        onReject: (...args: any[] ) => any = null,
        onSuccess: (...args: any[] ) => any = null,
        onError: (...args: any[] ) => any = null,
    ) : void
    {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.info,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value)
            {
                if( typeof onConfirm !== 'undefined' && onConfirm !== null )
                    onConfirm();

            }
            else if (result.dismiss === Swal.DismissReason.cancel)
            {
                if( typeof onReject !== 'undefined' && onReject !== null )
                    onReject();
            }
        })
    }

    /**
     * This function shows a swal error alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showErrorSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText:string = 'Sì',
        cancelButtonText:string = 'No',
        onConfirm: (...args: any[] ) => any = null,
        onReject: (...args: any[] ) => any = null,
        onSuccess: (...args: any[] ) => any = null,
        onError: (...args: any[] ) => any = null,
    ) : void
    {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.error,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value)
            {
                if( typeof onConfirm !== 'undefined' && onConfirm !== null )
                    onConfirm();

            }
            else if (result.dismiss === Swal.DismissReason.cancel)
            {
                if( typeof onReject !== 'undefined' && onReject !== null )
                    onReject();
            }
        })
    }

    /**
     * This function shows a swal info alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showWarningSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText:string = 'Sì',
        cancelButtonText:string = 'No',
        onConfirm: (...args: any[] ) => any = null,
        onReject: (...args: any[] ) => any = null,
        onSuccess: (...args: any[] ) => any = null,
        onError: (...args: any[] ) => any = null,
    ) : void
    {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.warning,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value)
            {
                if( typeof onConfirm !== 'undefined' && onConfirm !== null )
                    onConfirm();

            }
            else if (result.dismiss === Swal.DismissReason.cancel)
            {
                if( typeof onReject !== 'undefined' && onReject !== null )
                    onReject();
            }
        })

    }

    /**
     * This function shows a success info alert.
     *
     * @param {string} title - The message title.
     * @param {string} text - The message.
     * @param confirmButtonText
     * @param showCancelButton
     * @param cancelButtonText
     * @param {Function} onConfirm
     * @param {Function} onReject
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    public static showSuccessSwalAlert(
        title: string,
        text: string,
        showCancelButton: boolean = false,
        confirmButtonText:string = 'Sì',
        cancelButtonText:string = 'No',
        onConfirm: (...args: any[] ) => any = null,
        onReject: (...args: any[] ) => any = null,
        onSuccess: (...args: any[] ) => any = null,
        onError: (...args: any[] ) => any = null,
    ) : void
    {
        let self = this;

        Swal.fire({
            title: title,
            html: text,
            icon: self.type.success,
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: showCancelButton ? cancelButtonText : '',
            confirmButtonColor: "#19AEB8",
        }).then((result) => {

            if (result.value)
            {
                if( typeof onConfirm !== 'undefined' && onConfirm !== null )
                    onConfirm();

            }
            else if (result.dismiss === Swal.DismissReason.cancel)
            {
                if( typeof onReject !== 'undefined' && onReject !== null )
                    onReject();
            }
        })

    }
}