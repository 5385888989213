import router from "Router";

export const AjaxEndPoints = {

    /** Login **/
    ajaxLoginCheck : {nodo: router.generate('app.login_check')},

    /** Orders **/
    ajaxUpdateOrder : {nodo: router.generate('app.ajax_update_order')},

    /** save consents **/
    ajaxSaveConsents : {nodo: router.generate('app.save_user_consents')},

};